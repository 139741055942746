@import '../../../global_styles/variables.css';

.PanelContainer{
    background-color: var(--backgroundLight);
    position:fixed;
    display: flex;
    top: 88px;
    bottom: 0;
    flex-direction: column;
    left:0;
    width:var(--sidePanelWidth);
    overflow:hidden;
    justify-content: space-between;
}

.loiModalContainer {
    padding: 25px;
}

.loiButtonsContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.loiButtonsContainer > button {
    margin: 0 15px;
}

.QuestionSelectorPos{
    flex-shrink: 0;
    top:88px;
    width:var(--sidePanelWidth);
}

.QuestionSelectorPos > div {
    display: flex;
}
.QuestionSelectorPos > div > button {
    flex-grow: 1;
}
.ModuleBlock , .ModuleBlock > div {
    overflow: auto;
}

.ModuleBlock{
    margin-bottom:auto;
}

.ModuleBlock>button{
    float: right;    
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

.localQuestionsContainer {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
}

.localQuestionsContainer>button {
    font-size: 16px;
}

.BottomContainer {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: var(--sidePanelWidth);
    min-height: 55px;
    background-color: var(--backgroundLight);
}

.BottomContainer > button{
    position: relative;
}

.Border{
    border-top: 1px dashed #9499A3;
}

.Last{
    margin-bottom: -1px;
}

.Orange{
    border-color: var(--warningOrange);
}

.Red{
    border-color: var(--errorRed);
}

.Green{
    border-color: var(--okGreen);
}

.Ok{
    background-image: url('../../../assets/svg/ok.svg');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: contain;
    width: 15px;
    height: 15px;
    left: 95%;
    margin: 2px;
    margin-bottom: -17px!important;
    position: relative;
}

.Warning{
    background-image: url('../../../assets/svg/warning.svg');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: contain;
    width: 15px;
    height: 15px;
    left: 95%;
    margin: 2px;
    margin-bottom: -17px!important;
    position: relative;
    cursor: pointer;
}

.Error{
    background-image: url('../../../assets/svg/error.svg');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: contain;
    width: 15px;
    height: 15px;
    left: 95%;
    margin: 2px;
    margin-bottom: -17px!important;
    position: relative;
    cursor: pointer;
}

.Warninginfo {
    display: none;
}

.MandatoryOncePerYear {
    cursor: pointer;
    background-color: var(--backgroundWhite);
    width: 365px;
    box-shadow: 0px 5px 10px 3px var(--colorDisabled);
    z-index: 99;
    right: -2px;
    bottom: 29px;
    position: absolute;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    padding: 20px;
}
.MandatoryOncePerYear > p {
    margin-bottom: 0px;
}

.MandatoryOncePerYearCorner {
    width: 0;
    height: 0;
    position: absolute;
    right: 0px;
    bottom: -17px;
    z-index: 100;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 17.3px solid white; 
    filter: drop-shadow(0px 5px 3px var(--colorDisabled));
}

.Error:hover + .Warninginfo,
.Warning:hover + .Warninginfo {
    display: block;
    background-color: var(--backgroundWhite);
    height: auto;
    min-width: 300px;
    box-shadow: 0px 5px 10px 3px var(--colorDisabled);
    max-width: 600px;
    overflow: hidden;
    z-index: 99;
    left: 93%;
    position: relative;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 5px -17px 5px;
}

.WarningInfoHeader {
    font-weight: 900;
    padding-bottom: 20px;
}

.WarningInfoSubText {
  padding-bottom: 10px;
}

