@import '../../../global_styles/variables.css';

.Logo{
    width: var(--sidePanelWidth);
    height:var(--logoHeight);
    display:flex;
    flex-shrink: 0;
    align-items: center;
    padding-left: var(--basePadding);
    font-size: var(--h4FontSize);
    position:fixed;
    left:0;
    top:0;
    background-color: var(--backgroundLight);
}

.Link{
    color: var(--bs-body-color);
}

.Link:hover{
    color: var(--bs-body-color);
}

.Orange{
    color: var(--actionBase);
}