@import '../../../global_styles/variables.css';


.Wrapper {
    width: 100%;
    height: auto;
    border: 1px solid var(--strokeGray);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 15px 10px 15px 0px;
}

.SelectionMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px;
}

.btnContainer {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}


.label {
    font-weight: bold;
}
.middleLabel {
    margin-left:12px;
}

.rightLabel {
    margin-right: 47px;
}