@import '../../../global_styles/variables.css';

.error {
  border: 1px solid red;
  padding: 1rem 2rem;
}

.form {
  margin: 0 auto;
  padding: 1rem 2rem;
}

.title {
  font-size: 2.25rem;
  padding-top: 20px;
}

.form input,
.form textarea {
  border: 1px solid var(--strokeGray);
  border-radius: var(--radius);
  padding: 0.5rem 0.75rem;  
}


.form textarea {
  height: 138px;
}

.form input:focus,
.form textarea:focus {
  outline: 2px solid var(--colorFocus);
}

.formControl {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr;
}

.formControl label {
    font-size: var(--textDefault);
    font-weight: bold;
    padding-bottom: 0.5rem;    
}

.formControlRow {
  display: flex;
  align-items: top;

}

.formControlRow > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
}
.formControlRow input {
  width: 70%;
}
.formControlRow label {
  padding-bottom: 0;
}

.dropdown{
  width: 70%;
}

.dropdown > label{
  margin-bottom: 0.25rem;
}

.questionTextControl {
  margin-top: 0.5rem;
}

.tabContainer {
  padding-top: 0.5rem;
  display: flex;
  justify-content: right;
}

.tabContent {
  border: 1px solid var(--strokeGray);
  border-radius: var(--radius);
  padding: 0.5rem 0.75rem;
}

.btnContainer {
  padding-top: 1rem;
  display: flex;
  justify-content: right;
}


.localQuestionInput {
    margin: 5px 0 5px 0;
    border: 1px solid var(--strokeGray);
    border-radius: 4px;
    height: 40px;
    width: 100%;
    max-width: 264px;
}

.localQuestionLabel {
    margin-right: 0.5rem;
}

.btnContainerCenter {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}


.searchDropDowninput {
    width: 70%;    
    height: 40px;
}

