@import '../../../global_styles/variables.css';

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-right: 1px solid var(--strokeGray);
  border-bottom: 1px solid var(--strokeGray);
}

.cell {
  border-left: 1px solid var(--strokeGray);
  border-top: 1px solid var(--strokeGray);
  font-family: var(--globalFontFamily);
  line-height: var(--baseLineHeight);
}

.title {
  padding: 5px 0.25px;

  text-align: center;
  font-size: 1.125rem; /*18px*/
  font-weight: 600;
}

.content {
  padding: 3px 0.25px;

  text-align: center;
  font-size: var(--baseFontSize);
}

.lastrow {
  padding: 7px;
  font-size: var(--baseFontSize);
  grid-column: 1/-1;
}

.user {
  display: inline-block;
  padding-right: 22px;
}

.label {
  display: inline-block;
  text-align: right;
  padding-right: 24px;
  width: 130px;
}

.link a:link,
.link a:visited,
.link a:hover,
.link a:active {
  color: var(--actionBase);
}

/* type of status */
.success {
  color: var(--successGreen);
  font-weight: 600;
}

.progress {
  color: var(--progressOrange);
}

.error {
  color: var(--errorRed);
}
