@import "../../../global_styles/variables.css";

.Label {
  color: var(--textDefault);
  padding-left: var(--standardPadding);
  font-weight: bold;
}

.checkboxDiv {
  display: flex;
  cursor: pointer;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid transparent
}

.NoPadding {
  padding: 0;
}

.Checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid var(--strokeGray);
  border-radius: 4px;
  background-color: var(--actionContrast);
  flex-shrink: 0;
}

.Radiobutton {
  width: 24px;
  height: 24px;
  border: 1px solid var(--strokeGray);
  border-radius: 50%;
  background-color: var(--actionContrast);
  flex-shrink: 0;
}

.Checked {
  border: 1px solid var(--actionBase);
  background-color: var(--actionBase);
}

.Disabled {
  border: 1px solid var(--colorDisabled);
}
.Checked.Disabled{
  background-color: var(--colorDisabled);
}

.Checked::after, .Checked.Disabled::after{
  content: '';
  background-image: url("../../../assets/svg/check.svg");
  display: block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-position: 2px 5px;
}

.Active{
  background-color: var(--backgroundWhite);
  border: 1px solid var(--colorFocus);
}


.checkboxInput {
  width:1px;
  height:1px;
  opacity:0;
  visibility: hidden;
}