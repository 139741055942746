@import '../../../global_styles/variables.css';

.BtnCloseWrapper{
  padding: var(--buttonPadding);
  vertical-align: middle;
}

.ModuleList{
  display: flex;
  flex-direction: column;
}

.Title, .Message, .BtnWrapper, .ModuleList{
  padding: var(--standardPadding);
}

.BtnWrapper, .BtnCloseWrapper{
  float: right;
}

.BtnWrapper>button:last-child{
  margin-left: 20px;
}