@import '../../../global_styles/variables.css';

.Page {
  margin-left: var(--sidePanelWidth);
  display:flex;
}

.Page.Fullscreen{
  margin-left: 0;
  width: 100%;
  justify-content: center;
}

.loading {
  padding: 1rem 2rem;
}

.error {
  border: 1px solid red;
  padding: 1rem 2rem;
}
