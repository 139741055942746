@import '../../../global_styles/variables.css';

.Wrapper{
  width: 100%;
  height: auto;
  border: 1px solid var(--strokeGray);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 15px 10px 15px 10px;
}

.SelectionMenu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
}

.InputItem{
  width: 30%;
}

.InputItem label{
  padding-bottom: 5px;
}

.Type{
  padding-left: 10px;
}

.DisabledProcess{
  color: var(--colorDisabled);
  width: fit-content;
}

.NoProcess{
  flex-direction: row-reverse;
  align-items: center;
}

.Error{
  border-color: var(--errorRed);
}