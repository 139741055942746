@import "../../../global_styles/variables.css";
.Page {
  margin: auto;
  display: flex;
  overflow: hidden;

}

.bodyText {
  margin-left: var(--sidePanelWidth);
  margin-right: var(--contentPadding);
  margin-bottom: var(--contentPadding);
}
.headerPadding {
  margin-left: var(--contentPadding);
  margin-top: var(--contentPadding);
}
.localQuestionList {
  margin-left: 50px;
}
.labelHeader {
  color: var(--actionBase);
}
.navPath {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: var(--contentPadding);
}

.downloadbtnContainer {
  position: fixed;
  right: var(--standardPadding);
  bottom: var(--standardPadding);
}

.downloadbtnContainer>button:not(:last-child){
  margin-right: 10px;
}

.noDataErrorMessage {
  color: red;
  font-weight: bold;
  margin: 25px
}

.loading {
  padding: 1rem 2rem;
}

.headers {
  color: var(--textHeading);
  font-family: var(--globalFontFamily);
}

.contentBold {
  font-weight: bold;
}
.notificationMsg {
  color: red;
  margin-top: var(--standardPadding);
  margin-left: var(--contentPadding)!important;
  margin-bottom: -70px!important;
}

