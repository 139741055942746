@import '../../../global_styles/variables.css';


.Wrapper {
    width: 100%;
    height: auto;
    border: 1px solid var(--strokeGray);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 15px 10px 15px 0px;
}

.SelectionMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px;
}

.InputItem {
    width: 12rem;
}

    .InputItem label {
        padding-bottom: 5px;
    }

.Type {
    padding-left: 10px;
}

.DisabledProcess {
    color: var(--colorDisabled);
    width: fit-content;
}

.NoProcess {
    flex-direction: row-reverse;
    align-items: center;
}

.Error {
    border-color: var(--errorRed);
}

.Textareacss {
    /*height: 85px;*/
    width: 50rem;
}

.btnContainer {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}

.parent {
    border: 1px solid black;
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
}

.child {
    display: inline-block;
    border: 1px solid red;
    padding: 1rem 1rem;
    vertical-align: middle;
}

.leftbox {
    float: left;
    background: Red;
    width: 25%;
    height: 280px;
}

.middlebox {
    float: left;
    background: Green;
    width: 50%;
    height: 280px;
}

.rightbox {
    float: right;
    background: blue;
    width: 25%;
    height: 280px;
}
.leftarea {
    float: left;
    width: 33%;
}

.rightarea {
    float: right;
    width: 24%;
}

.middlearea {
    margin-left: 10px;
    width: 18%;
}

.Close {
    float: right;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--actionBase);
    padding: 0 20px 0 20px;
}
.btnrestore {
    margin-left: auto;
    border: 1px solid var(--strokeGray);
    border-radius: 100px;
    background-color: #fff;
    scale: 0.93;
    color: var(--actionBase);
}