@import '../../../global_styles/variables.css';

.container {
  border-bottom: 1px solid var(--strokeGray);
}

.item {
  padding: 16px 0;
  border-top: 1px solid var(--strokeGray);
  margin: -1px 0;
}

.item header {
  padding: 0 8px;
  display: flex;

  color: var(--textSecondaryInteractive);
  font-family: var(--globalFontFamily);
  font-size: 1.125rem; /*18px*/
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
}

.itemBtn {
  padding: 0;
  width: auto;
  margin-right: 8px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--textSecondaryInteractive);
  text-align: left;
}

.btnContainer {
  display: flex;
  gap: 14px;
  margin-bottom: 14px;
}

.itemBtn:hover,
.itemBtn:active {
  color: var(--actionBase);
}

.itemBtn:focus {
  outline: 1px solid var(--colorFocus);
}

@keyframes grow {
  from {
    max-height: 0;
  }
  to {
    max-height: 400px;
  }
}

.detail {
  border: 1px solid var(--strokeGray);
  margin: -1px 0;
  padding: 0;
  overflow: hidden;
  animation-name: grow;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.detail header {
  padding: 16px 8px;
  display: flex;

  color: var(--textSecondaryInteractive);
  font-family: var(--globalFontFamily);
  font-size: 1.25rem; /*20px;*/
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
}

.detailBtn {
  padding: 0;
  width: auto;

  border-radius: 4px;
  background-color: transparent;
  border: none;

  color: var(--actionBase);
}

.detailContent {
  padding: 8px 20px;
}

.detail footer {
  display: flex;
  padding: 8px 20px;
  justify-content: flex-end;
}

.displayRight {  
  display: flex;
  flex-direction: column;
  text-align: right;
}

.displayRight > button {
  margin: 4px 0;
  text-align: center;
}

.displayLeft{
  display:flex;
}