.contentBold {
  font-weight: bold;
}

.AnswerList{
  list-style-type: none; 
  padding: 0;
  margin: 0;
}

.ShowListItem{
  display:flex;
}

.HideListItem{
  display: none;
}

.ExpandToggle{
  padding-top: 3px;
  font-style: italic;
  cursor: pointer;
}

.AnswerCode{
  min-width: 40px;
  text-align: right;
  padding-right: 4px;
  flex-shrink: 0;
}