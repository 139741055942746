@import '../../../global_styles/variables.css';

.PageWrapper{
  margin-left: 125px;
  margin-right: 125px;
}

.Title{
  border-bottom: 2px solid var(--strokeGray) ;
  font-weight: 700;
}

.PageContent{
  padding: 40px;
}

.FlexWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BtnWrapper{
  margin: 15px 0 15px 0
}

.BtnDelete{
  margin-right: auto;
}

.BtnWrapper.Last{
  margin-left: 20px;
}

.SaveBtns{
  display: flex;
  justify-content: flex-end;
  margin-top: 30;
}

.FullscreenLogo{
width: 100%;
z-index: 1
}

.dropdown{
  width: 30%;
  max-width: 264px;
  min-width: 188px
}

form>label{
  padding: 10px 0 10px 0;
}

form>label>input{
  display: block;
  margin: 5px 0 5px 0;
  border: 1px solid var(--strokeGray);
  border-radius: 4px;
  height: 40px;
  width: 100%;
  max-width: 264px;
}

.Error{
 border-color: var(--errorRed);
}

form{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}

.Notification_title{
  color: var(--errorRed) 
}

.SuccessMsg{
  color: var(--successGreen);
  font-style: italic;
}