@import "../../../global_styles/variables.css";


.button {
  font-family: var(--globalFontFamily);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  width: 162px;
  font-weight: bold;
  color: var(--textDefault);
  background-color: var(--backgroundLight);
  padding: var(--buttonPadding)
}

.activeBtn{
  color: var(--actionBase);
  background-color: var(--actionContrast);
}

.borderMiddle {
  border: 1px solid var(--strokeGray);
  border-radius: 0;
  border-left: 0;
}

.borderLeft {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.borderRight {
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

