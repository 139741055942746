@import '../../../global_styles/variables.css';

.btn_container {
  display: flex;
}

.btn_container button {
  border-radius: 3px;
  background-color: transparent;
  border: solid 1px transparent;

  padding: 12;
  height: 43px;

  color: #414752;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 150%;
  font-weight: 500;
}

.btn_container_clicked {
  border: 1px solid #fed79a;
  background-color: var(--backgroundWhite);
}

.btn_container button:hover,
.btn_container button:active {
  color: #e55a00;
}

.btn_container button:first-child {
  text-align: start;
}
.btn_container button:nth-child(2) {
  width: 20%;
}

.icon_btn {
  margin-left: auto;
}

.icon_btn img {
  height: 19px;
}
