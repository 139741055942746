@import "../../../global_styles/variables.css";

.label {
    font-family: var(--globalFontFamily);
    font-size: var(--globalFontSize);
}

.textInput {
    height: 3.2rem;
    width: 14rem;
}

.textInput:focus {
    outline: 1px solid var(--actionBase);
}

.textInputError {
    color: red;
    font-family: var(--globalFontFamily);
    font-size: var(--globalFontSize);
}