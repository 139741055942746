@import '../../../global_styles/variables.css';

.headers {
  color: var(--textHeading);
  font-family: var(--globalFontFamily);
  /* font-size: var(--h1FontSize);
  line-height: var(--h1LineHeight); */
  /* padding: var(--standardPadding); */
}

.content {
  color: var(--textDefault);
  font-family: var(--globalFontFamily);
  font-size: var(--globalFontSize);
  line-height: var(--baseLineHeight);
  padding-top: var(--contentPadding);
}

.contentBold {
  font-weight: bold;
}

.contentItalic{
  font-style: italic;
}

.moduleContainer {
  padding: var(--contentPadding);
}

.QnDetails{
  padding-bottom: var(--standardPadding);
}

.notificationMsg {
    color: red;
}

.QnLogicInstructions{
  padding-bottom: var(--textIndentation);
}

.QuestionWrapper>div{
  padding-top: 5px;
  padding-bottom: 5px;
}

.QnDetailsToggle{
  font-style: italic;
  cursor: pointer;
  padding-bottom: var(--standardPadding);
}
