@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

@import '../../../global_styles/variables.css';

/* To change font family and font size */
.e-richtexteditor .e-rte-content .e-content,
.e-richtexteditor .e-source-content .e-content {
  font-size: var(--baseFontSize);
  font-family: var(--globalFontFamily);
}

/* To change font color and content background */
.e-richtexteditor .e-rte-content,
.e-richtexteditor .e-source-content {
  background: var(--backgroundWhite);
  color: var(--textDefault);
}

/* To change font color for toolbar icon */
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-icons:active {
  color: var(--textDefault);
}

/* To change font color for toolbar button */
.e-toolbar .e-tbar-btn,
.e-toolbar .e-tbar-btn:active,
.e-toolbar .e-tbar-btn:hover {
  color: var(--textDefault);
}

/* To change font color for toolbar button in active state*/
.e-richtexteditor
  .e-rte-toolbar
  .e-toolbar-item
  .e-dropdown-btn.e-active
  .e-icons,
.e-richtexteditor
  .e-rte-toolbar
  .e-toolbar-item
  .e-dropdown-btn.e-active
  .e-rte-dropdown-btn-text {
  color: var(--actionBase);
}

/* To change font color for expanded toolbar items */
.e-richtexteditor
  .e-rte-toolbar
  .e-toolbar-extended
  .e-toolbar-item
  .e-tbar-btn
  .e-icons,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn {
  color: var(--textDefault);
}
