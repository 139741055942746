.wrapper{
 max-width: 85%;
 margin-left: 5rem;
 margin-top: 5rem;
}

.bold{
  font-weight: bold;
}

.description{
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.btnContainer{
  display: flex;
  justify-content: flex-end;
}