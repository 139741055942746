@import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-notifications/styles/material.css";



  .e-toast-container .e-toast .e-toast-message .e-toast-title {
    color: #333;
    font-size: 18px;
    font-weight: bold;
}

.e-toast-container .e-toast .e-toast-message .e-toast-content {
    color: #333;
    font-size: 13px;
    font-weight: normal;
}

.e-toast-container .e-toast .e-toast-icon {
    color: var(--actionBase);
}

.e-toast-container .e-toast {
    background-color: #ffffff;
}

.e-toast-container .e-toast:hover {
    background-color: #ffffff;
}

.e-toast-container .e-toast .e-toast-progress {
    background-color: var(--actionBase);
}
.e-toast-container .e-toast .e-toast-close-icon.e-icons,
.e-toast-container .e-toast .e-toast-close-icon.e-icons:hover,
.e-toast-container .e-toast .e-toast-close-icon.e-icons:focus {
    background-color: transparent;
    border-radius: 50%;
    color: var(--actionBase);
}