@import "./global_styles/variables.css";

.toolbar {
  height: 88px;
  padding-left: var(--sidePanelWidth);
  display: flex;
  justify-content: space-between;
}

.signin {
  background-color: var(--actionContrast);
  text-transform: uppercase;
  border-radius: 4px;
  color: var(--actionBase);
}

.signin:hover {
  background-color: var(--actionBase);
  color: var(--actionContrast);
}

.body {
  padding-top: 20px;
}

.unAuthenticatedBody {
  padding-top: 20px;
  text-align: center;
}
.user {
  color: var(--textDefault);
  padding: 14px;
  display: flex;
  align-items: center;
  z-index: 2
}
.logout {
  height: 20px;
  width: 30px;
  cursor: pointer;
}

.leftToolbar{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding:0 24px;
  z-index: 2
}