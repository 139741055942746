@import '../../../global_styles/variables.css';

.error {
  border: 1px solid red;
  padding: 1rem 2rem;
}

.form {
  padding: 1rem 0rem;
  margin: 0 var(--contentPadding);
}

.title {
  font-size: 2.25rem;
  padding-top: 20px;
}

.form input,
.form textarea {
  border: 1px solid var(--strokeGray);
  border-radius: var(--radius);
  padding: 0.5rem 0.75rem;
}

.form textarea {
  height: 138px;
}

.form input:focus,
.form textarea:focus {
  outline: 2px solid var(--colorFocus);
}

.formControl {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr;
}

.formControl label {
  font-size: var(--textDefault);
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.questionTextControl {
  margin-top: 0.5rem;
}

.tabContainer {
  padding-top: 0.5rem;
  display: flex;
  justify-content: right;
}

.tabContent {
  border: 1px solid var(--strokeGray);
  border-radius: var(--radius);
  padding: 0.5rem 60px;
}

.btnContainer {
  padding-top: 1rem;
  display: flex;
  justify-content: right;
  gap: 20px;
}

.CheckboxContainer{
    margin-left:-14px;
}

.NoSpacing{
  padding: 0;
  margin: 0
}