@import '../../../global_styles/variables.css';

.Page {
    margin-left: var(--sidePanelWidth);
    padding-bottom:50px;
}

.confirmContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 10;
}

.buttonMargin {
  display: flex; padding: 4px; gap: 4px;
}

/* .Icon{
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .IconsMerged{
  background-image:  url('../../../assets/svg/checkBtn_white.svg'), url('../../../assets/svg/closeBtn_white.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: left, right;
  width: 25px;
  height: 16px;
}

.IconClose{
  background-image: url('../../../assets/svg/closeBtn_white.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 8px;
  height: 8px;
}

.IconCheck{
  background-image: url('../../../assets/svg/check.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 10px;
  height: 7px;
} */
