@import '../../../global_styles/variables.css';

.LOI_aboveThreshold {
  color: var(--errorRed);
}

.LOI {  
  position: relative;
  width: 300px;
  background-color: var(--backgroundLight);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .LOI {
      width:226px;
  }
}