:root {
  /* Colors */

  /* Action */
  --actionBase: #e55a00;
  --actionContrast: #ffffff;

  /* Text */
  --textDefault: #414752;
  --textHeading: #323842;
  --textSecondaryInteractive: #5c626e;

  /* Background */
  --backgroundLight: #f5f5f5;
  --backgroundWhite: #ffffff;

  /* Other */
  --strokeGray: #bbc0c9;
  --errorRed: #bc1c0e;
  --colorDisabled: #9499a3;
  --colorFocus: #fed79a;
  --errorRed: #bc1c0e;
  --warningOrange: #FFA52F;
  --okGreen: #167922;
  --successGreen: #0a9932;
  --progressOrange: #ffa52f;
  --darkOrange: #CF4800;
  --darkGray: #6f7582;

  /* Fonts */
  --globalFontFamily: 'Lato', sans-serif;
  --globalFontSize: 16px;

  /* Base font */
  --baseFontSize: 1rem;
  --baseLineHeight: 1.5rem;

  /* Headers */
  --h1FontSize: 2rem;
  --h1LineHeight: 3rem;
  --h2FontSize: 1.75rem;
  --h2LineHeight: 2.625rem;
  --h3FontSize: 1.5rem;
  --h3LineHeight: 2.25rem;
  --h4FontSize: 1.25rem;
  --h4LineHeight: 1.875rem;

  /* Other */
  --buttonFontSize: 1.125rem;
  --buttonLineHeight: 1.6875rem;
  --microFontSize: 0.75rem;
  --microLineHeight: 1.125rem;
  --radius: 3px;

  /* Paddings */
  --standardPadding: 0.75em;
  --buttonPadding: 0.5em 1em;
  --contentPadding: 80px;
  --basePadding: 32px;

  /* Side Panel */
  --sidePanelWidth: 400px;

  /*Indentation*/
  --textIndentation: 20px;
  /* Logo Height */
  --logoHeight: 88px;

}

/* <=1200 */


@media screen and (max-width: 1200px) { 
  :root { 
      --sidePanelWidth: 324px!important; 
  } 
}
