  .PageToggleWrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
  }

  .PageToggleText {
    margin-right: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .PageToggle {
    width: 40px;
    height: 24px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image:  url('../../../assets/svg/checkBtn_white.svg'), url('../../../assets/svg/closeBtn_white.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: 5px, 25px;
  }

  .PageToggle.On{
    background-color: var(--actionBase);
  }
  
  .PageToggle.Off{  
    background-color: var(--darkGray);
  }

  .CircleBtn{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--backgroundWhite);
    margin-left: 5px;
    transition: margin 200ms;
    cursor: pointer;
  }
  
  .PageToggle.On .CircleBtn{
    margin-left:20px;
  }