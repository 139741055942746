@import '../../../global_styles/variables.css';

.item {
  border-bottom: 1px dashed #ccc;
  margin: 0 4em;
}

.header {
  display: flex;
  width: 100%;
}

.btn {
  border-radius: 3px;
  background-color: transparent;
  border: none;
  width: auto;
  text-align: left;
  color: var(--textSecondaryInteractive);
  font-family: var(--globalFontFamily);
  font-size: 1.125rem; /*18px*/
  line-height: var(--buttonLineHeight);
  font-weight: 600;
}

.alignRight {
  margin-left: auto;
}

.subitemBtn {
  display: block;
  /*padding: 0.5rem 0 0.5rem 4rem; /*8px 24px 8px 68px;*/
  padding: 0.5rem 0;
  padding-left: 4rem;
  text-align: left;
  font-size: var(--globalFontSize);
  width: 100%;
}

.btn:hover,
.btn:active,
.subitemBtn:hover,
.subitemBtn:active,
.activeBtn {
  color: var(--actionBase);
}

.btn:focus,
.subitemBtn:focus {
  outline: 2px solid var(--colorFocus);
}

@media screen and (max-width: 1200px) { 
  .item {
    margin:0;
    padding-left: 1em;
  }
}