@import '../../../global_styles/variables.css';

.CrumbContainer{
    display:flex;
}

.Link{
    color: var(--actionBase);
    padding: 0 4px;
    text-decoration: none;
}

.Link:hover{
    color: var(--darkOrange);
    text-decoration: underline;
}

.Current{
    color: var(--textDefault);
    font-weight: bold;
    padding: 0 4px;
}