
.DropDownContainer{
  margin:20px;
  margin-top:5px;
  width:250px;
  height:32px;
  position: relative;
  cursor: pointer;
}
.Hidden{
    display:none;
}

.DropDownContainer::after{
  content: " ";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 8px;
  top: 6px;
  /* background-image: URL('../../assets/chevDown.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto; */
}

.DropDownSearch{
  width:100%;
  height:40px;
  border: 1px solid #BBC0C9;
  box-sizing: border-box;
  border-radius: 4px;
  padding:4px 24px 4px 8px ;
  background-color: #fff;
  color: #414752;
  font-size: 16px;
}


.ListContainer{
  width:100%;

  border: 1px solid #BBC0C9;
  box-sizing: border-box;
  border-radius: 4px; 
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.10);
  background-color: #fff;
  color: #414752;
  font-size: 16px;
  margin: 4px 0;
  max-height: 17vh;
  overflow-y: auto;
}

.ListItem{
    padding: 6px;
    margin-left: 2px;
    text-align: left;
    display: flex;
    align-items: center;
}

.Hovered{
    background-color:#ccc
}