@import '../../../global_styles/variables.css';

.Title,
.Message,
.Btn {
  padding: 20px;
}

.Close {
  float: right;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--actionBase);
  padding: 0 20px 0 20px;
}

.btnContainer {
  margin: 1rem;
  display: flex;
  justify-content: right;
  gap: 20px;
}
.userInput {
  width: 100%;
  min-height: 80px;
}
.Message {
  border-top: 1px solid var(--strokeGray);
  border-bottom: 1px solid var(--strokeGray);
}
.Title { 
  font-weight: bold;
}

.header {
  border-bottom: 1px solid var(--strokeGray);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
