.error {
  border: 1px solid red;
  padding: 1rem 2rem;
}

.PanelContainer {
  background-color: var(--backgroundLight);
  position: fixed;
  top: 88px;
  left: 0;
  /* bottom: calc(72px + 45px); */
  bottom: 0;
  width: var(--sidePanelWidth);
  overflow: auto;
}

.AdHocBtnContainer {
  position: fixed;
  bottom: 72px;
  height: 45px;
  width: 324px;
  background-color: var(--backgroundLight);
  display: flex;
  justify-content: flex-start;
  padding: 1px 20px;
  border-top: 1px solid var(--strokeGray);
  border-bottom: 1px solid var(--strokeGray);
}