@import '../../../global_styles/variables.css';

.Dropdown{
  display: flex;
  flex-direction: column;  
}

.Dropdown label{
  padding-bottom: 5px;
}
.Dropdown select{
  word-wrap: normal;
  border: 1px solid var(--strokeGray);
  border-radius: 4px;
  height: 40px;
  /* min-width: 150px; */
  /* max-width: 300px; */
  /* width: 100%; */
}