@import '../../../global_styles/variables.css';

.Title,
.Message,
.Btn {
  padding: var(--standardPadding);
}

.Close {
  float: right;
  padding: var(--buttonPadding);
  cursor: pointer;
}

.btnContainer {
  margin: 1.5rem;
  margin-left: 3rem;
  display: flex;
  justify-content: right;
  gap: 20px;
}
