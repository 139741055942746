@import "../../../../global_styles/variables.css";

.sidebar {
  z-index: 1;
  width: var(--sidePanelWidth);
  top: var(--logoHeight);
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: var(--backgroundLight);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.sidePanelItems {
  margin-bottom: 20px;
  overflow: auto;
}
.sidePanelItem {
  cursor: pointer;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.sidePanelCustomQuestion {
  padding-left: 45px;
}

.label {
  color: var(--textDefault);
  padding-left: var(--standardPadding);
  font-weight: bold;
}

.labelCustomQuestion {
  padding-left: 0px;
}
.labelHeader {
  color: var(--actionBase);
}
.active {
  background-color: var(--backgroundWhite);
  border: 1px solid var(--colorFocus);
}

.BottomContainer {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: var(--sidePanelWidth);
  min-height: 55px;
  background-color: var(--backgroundLight);
}