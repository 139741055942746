@import '../../../global_styles/variables.css';

.btn {
  color: var(--textDefault);
  background-color: var(--backgroundLight);
  border: solid 1px var(--strokeGray);
  border-bottom: none;
  border-right: none;
  font-size: var(--buttonFontSize);
  font-weight: 600;
  height: 40px;
  width: auto;
}

.btnFirst {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btnLast {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-right: solid 1px var(--strokeGray);
}

.btn:focus {
  outline: 2px solid var(--colorFocus);
}

.activeBtn {
  color: var(--actionBase);
  background-color: var(--backgroundWhite);
}
