
.DropDownContainer{
  margin:20px;
  margin-top:5px;
  width:280px;
  height:32px;
  position: relative;
  cursor: pointer;
}

.DropDownTitle{
  width:100%;
  height:32px;
  border: 1px solid #BBC0C9;
  box-sizing: border-box;
  border-radius: 4px;
  padding:4px 8px 4px 8px ;
  background-color: #fff;
  color: #414752;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}


.ListContainer{
  width:100%;
  border: 1px solid #BBC0C9;
  box-sizing: border-box;
  border-radius: 4px; 
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.10);
  background-color: #fff;
  color: #414752;
  font-size: 16px;
  margin: 4px 0;
  overflow-y: auto;
  max-height: 17vh;
  transition: max-height 0.3s ease-in-out;
  animation: 7s delay_overflow;
}

.Hidden{
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  box-shadow: none;
  overflow-y: hidden;
  border-width: 0;
  max-height:0;
}

@keyframes delay_overflow {
  from { overflow-y: hidden; }
}

.ListItem{
    padding: 4px;
    margin-left: 2px;
    text-align: left;
    display: flex;
    align-items: center;
}


.NoMargin{
  margin: 0;
}


.ExtraBottomPadding{
  margin-bottom:40px
}