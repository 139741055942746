@import '../../../global_styles/variables.css';

.Title, .Message, .BtnWrapper{
  padding: var(--standardPadding);
}

/* .Button{
  float: right;
  padding: var(--buttonPadding);
} */

.Message{
  border-top: 1px solid var(--backgroundLight);
  border-bottom: 1px solid var(--backgroundLight);
}

.BtnWrapper, .BtnCloseWrapper{
  float: right;
}

.BtnWrapper>button:last-child{
  margin-left: 20px;
}

.BtnCloseWrapper{
  padding: var(--buttonPadding);
  vertical-align: middle;
}
