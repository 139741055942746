@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";

@import '../../../global_styles/variables.css';



.e-input-group.e-control-wrapper.e-date-wrapper::before,
.e-input-group.e-control-wrapper.e-date-wrapper::after,
.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::before,
.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::after{
  background: none;
  box-shadow: none;
  border-color: var(--strokeGray);
  /* border-radius: 4px;
  box-shadow: 0px 0px 1px #FED79A; */
}

.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error){
  border-width: 1px;
  border-color: var(--strokeGray);
}

.e-input-group.e-control-wrapper.e-date-wrapper{
  border: 1px solid var(--strokeGray);
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  height: 40px;
  min-width: 150px;
  max-width: 300px;
}
.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus{
  border: 1px solid var(--strokeGray);
  border-radius: 4px;
  box-shadow: 0px 0px 4px var(--colorFocus);
}

/*calendar icon*/
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active{
  color: var(--strokeGray)
}

/*selected date*/
.e-calendar .e-content td.e-today.e-selected:hover span.e-day, .e-calendar .e-content td.e-selected:hover span.e-day, .e-calendar .e-content td.e-selected.e-focused-date span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day{
  background-color: var(--textDefault);
  border-color: var(--textDefault);
}

/*TODO: 
*fix hover on today
*fix color when today selected
*change color on month selection
*change color on year selection
*/

/*today - date*/
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day, .e-calendar .e-content td.e-focused-date.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day{
  border-color: var(--textDefault);
}
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day, .e-calendar .e-content td.e-today span.e-day{
  border-color: var(--textDefault);
  color: var(--textHeading)
}
.e-calendar .e-content td.e-today:hover span.e-day{
  color: var(--textHeading)
}
.e-calendar .e-content td.e-today:hover span.e-day, .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day{
  border-color: var(--textDefault);
  /* color: var(--actionContrast) */
}

/*today - button at bottom of calendar*/
.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary, .e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover{
  color: var(--textDefault);
}
.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover{  
  background-color: var(--backgroundLight);
}

/*month*/
.e-calendar .e-content td.e-selected span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day, .e-calendar .e-content.e-year td.e-selected:hover span.e-day, .e-calendar .e-content.e-decade td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day{
  background-color: var(--textDefault);
}