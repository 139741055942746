
.error {
  border: 1px solid red;
  padding: 1rem 2rem;
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0;
}

.footer {
  text-align: center;
  margin-top: 1rem;
}

.container {
  padding: var(--contentPadding);
  padding-top:0;
  max-width:950px;
  width:100%;
}

.modalContentFooter, .modalContentHeader, .modalContentMain {
  padding: var(--textIndentation) var(--textIndentation) 0 var(--textIndentation);
}

.modalContainer p {
  margin-bottom: 0;
}

.modalContentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--strokeGray);
  padding-bottom: var(--textIndentation);
}

.modalContentHeader > p {
  font-weight: bold;
  font-size: 1.15rem;
}

.modalContentFooter {
  text-align: right;
  padding-bottom:var(--textIndentation);
}

.modalContentFooter button {
  margin-right: 8px;
}
.ProjectFilter{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: var(--basePadding);
}

.CountryHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}