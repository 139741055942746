.Modal{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  margin-bottom: 0 !important;
}

.ModalContent{
  width: auto; /*will probably change*/
  min-width: 30%;
  max-width: 80%;
  background-color: #fff;
  border: 1px solid var(--strokeGray);
  border-radius: 4px;
}