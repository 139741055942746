@import "../../../global_styles/variables.css";

.label {
  font-family: var(--globalFontFamily);
  font-size: var(--globalFontSize);
  font-weight: bold;
  padding: 0.625rem 0rem 0.625rem 0rem;
}

.textArea {
  height: 3.25rem;
  width: 50rem;
}

.textArea:focus {
  outline: 1px solid var(--actionBase);
}

.textAreaError {
  color: var(--errorRed);
  font-family: var(--globalFontFamily);
  font-size: var(--globalFontSize);
}
.textArea-fullwidth {
  width: 100%;
  min-height: 80px;
}
