@import '../../../global_styles/variables.css';

.Title, .Message, .DeleteError, .Btn{
  padding: var(--standardPadding);
}

.Close{
  float: right;
  padding: var(--buttonPadding);
  cursor: pointer;
}

.Message{
  border-top: 1px solid var(--backgroundLight);
  border-bottom: 1px solid var(--backgroundLight);
}

.Input{
  padding-top: var(--standardPadding)
}

.Message input{
  width: 70%
}

.DeleteError{
  color: var(--errorRed)
}
