@import '../../../global_styles/variables.css';

.btn {
  border-radius: 4px;
  padding: var(--buttonPadding);
  padding-top: 0.35rem;
  box-sizing: border-box;
  width: auto;
  height: 40px;

  font-family: var(--globalFontFamily);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  font-weight: 500;
}

/* .btn:focus {
  outline: 2px solid var(--colorFocus);
} */

.default {
  background-color: var(--backgroundLight);
  border: solid 1px var(--backgroundLight);

  color: var(--textDefault);
}

.default:hover,
.default:active {
  color: var(--actionBase);
}

.default:disabled {
  color: var(--colorDisabled);
}

/* primary: orange background, white font */
.primary {
  background-color: var(--actionBase);
  border: solid 1px var(--actionBase);

  color: var(--actionContrast);
}

.primary:hover,
.primary:active {
  color: var(--strokeGray);
}

.primary:disabled {
  background-color: var(--backgroundLight);
  border: solid 1px var(--colorDisabled);

  color: var(--colorDisabled);
}

/* secondary: white background, orange font */
.secondary {
  background-color: var(--backgroundWhite);
  border: solid 1px var(--actionBase);

  color: var(--actionBase);
}

.secondary:hover,
.secondary:active {
  background-color: var(--backgroundLight);
  border: solid 1px var(--actionBase);

  color: var(--actionBase);
}

.secondary:disabled {
  background-color: var(--backgroundLight);
  border-color: var(--strokeGray);

  color: var(--colorDisabled);
}

.selection {
  border-radius: 100px;
  padding: 4px 4px 4px 8px;
  box-sizing: border-box;

  font-size: 14px;
  line-height: normal;

  background-color: transparent;
  border: solid 1px var(--strokeGray);
}

.selection:hover,
.selection:active {
  color: var(--actionBase);
}

.bold {
  border-radius: 50px;
  box-sizing: border-box;

  font-weight: bold;
  font-size: 1.25rem;
  line-height: var(--buttonLineHeight);

  background-color: transparent;
  border: solid 1px var(--strokeGray);
}

.bold:hover,
.bold:active {
  color: var(--actionBase);
}

.close{
  margin-left: auto;
  background-image: url('../../../assets/svg/closeBtn.svg');  
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: #fff;
  padding-right: 0;
}

.close:hover,
.close:active{
  color: gray
}

.selectionMini{
  margin-left: auto;
  border: 1px solid var(--strokeGray);
  border-radius: 100px;
  background-color: #fff;
  scale: 0.85;
}

.selectionMini:hover,
.selectionMini:active {
  color: var(--actionBase);
}



.button-specificclose{  
  background-image: url('../../../assets/svg/closeBtn.svg');  
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: #fff;
  padding-right: 0;
}