@import "./variables.css";


body{
    font-family: var(--globalFontFamily);
    font-size: var(--globalFontSize);
}

h1{
    font-size: var(--h1FontSize);
    line-height: var(--h1LineHeight);
}

h2{
    font-size: var(--h2FontSize);
    line-height: var(--h2LineHeight);
}

h3{
    font-size: var(--h3FontSize);
    line-height: var(--h3LineHeight);
}

h4{
    font-size: var(--h4FontSize);
    line-height: var(--h4LineHeight);
}

/*Force-hiding syncfusion error on RichTextEditor*/
#js-licensing{
    display: none;
}