.Tooltip {
    cursor: pointer;
    background-color: var(--backgroundWhite);
    width: 365px;
    box-shadow: 0px 5px 10px 3px var(--colorDisabled);
    z-index: 99;
    right: -2px;
    bottom: 29px;
    position: absolute;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    padding: 20px;
}

.Tooltip > div {
    margin-bottom: 0px;
}

.TooltipCorner {
    width: 0;
    height: 0;
    position: absolute;
    right: 0px;
    bottom: -17px;
    z-index: 100;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 17.3px solid white; 
    filter: drop-shadow(0px 5px 3px var(--colorDisabled));
}

.Icon {
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: contain;
    width: 15px;
    height: 15px;
    left: 95%;
    margin: 2px;
    margin-bottom: -17px!important;
    position: relative;
}

.Ok{
    background-image: url('../../../assets/svg/ok.svg');
}

.Warning{
    background-image: url('../../../assets/svg/warning.svg');
}

.Error{
    background-image: url('../../../assets/svg/error.svg');
}

